import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from './logo.png';
import streamerImage from './image.png';
import sponsorsImage1 from './sponsor1.png';
import sponsorsImage2 from './sponsor2.png';
import sponsorsImage3 from './sponsor3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FaArrowUp } from 'react-icons/fa';
import ModifyLinks from './ModifyLinks';
import { Modal, Button } from 'react-bootstrap';

function Home() {
  const [showScroll, setShowScroll] = useState(false);
  const [links, setLinks] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      setShowScroll(window.pageYOffset > 400);
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, []);

  useEffect(() => {
    fetch('/links.json')
      .then(response => response.json())
      .then(data => {
        setLinks(data);
      })
      .catch(error => console.error('Error fetching links:', error));
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <div className="App">
      <header className="container-fluid text-center py-3">
        <div className="d-flex justify-content-between align-items-center container">
          <div className="logo">
            <img src={logo} alt="Logo" className="img-fluid" />
          </div>
          <nav className="d-flex">
            <a href="#" className="mx-2">HOME</a>
            <a href="#" className="mx-2" onClick={handleModalShow}>GC LINK FOR CI CO</a>
            <a href="#" className="mx-2">FAQ</a>
          </nav>
        </div>
      </header>
      <main className="container text-center my-5">
        <div className="row">
          <div className="col-md-6 my-auto text-white">
            <h1 className="main-text">Srad TV</h1>
            <h2 className="sub-text">Streamer</h2>
            <button className="btn btn-outline-primary enhanced-button mt-3" onClick={() => window.location.href = links[links.Platform]}>Register</button>
          </div>
          <div className="col-md-6">
            <div className="image-container">
              <img src={streamerImage} alt="Srad TV Streamer" className="img-fluid" />
            </div>
          </div>
        </div>
      </main>
      <div className="sponsors d-flex justify-content-center flex-wrap">
        <img src={sponsorsImage1} alt="Sponsor 1" className="sponsor-img mx-5 my-3" />
        <img src={sponsorsImage2} alt="Sponsor 2" className="sponsor-img mx-5 my-3" />
        <img src={sponsorsImage3} alt="Sponsor 3" className="sponsor-img mx-5 my-3" />
      </div>
      <footer className="container-fluid text-center py-3">
        <div className="social-icons">
          <a href="https://www.facebook.com/sradtv" target="_blank" rel="noopener noreferrer" className="mx-2">
            <FontAwesomeIcon icon={faFacebook} size="1x" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="mx-2">
            <FontAwesomeIcon icon={faInstagram} size="1x" />
          </a>
          <a href="https://www.discord.com" target="_blank" rel="noopener noreferrer" className="mx-2">
            <FontAwesomeIcon icon={faDiscord} size="1x" />
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="mx-2">
            <FontAwesomeIcon icon={faYoutube} size="1x" />
          </a>
        </div>
        <div>© Srad Tv - srad.tv</div>
      </footer>
      {showScroll && (
        <FaArrowUp className="scroll-to-top" onClick={scrollTop} />
      )}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select an Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="primary" onClick={() => window.location.href = links['escala.gc']} className="m-2">Escala</Button>
          <Button variant="secondary" onClick={() => window.location.href = links['maharlika.gc']} className="m-2">Maharlika</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/modify_json" element={<ModifyLinks />} />
      </Routes>
    </Router>
  );
}

export default App;
